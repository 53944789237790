module directives {
    export module integration {
        interface IImportVesselScheduleDirective extends ng.IScope {
            importVesselScheduleData: interfaces.logistics.ImportVesselScheduleDashboardView[];
            filteredImportVesselScheduleData: interfaces.logistics.ImportVesselScheduleDashboardView[];
            importVesselScheduleSummaryData: interfaces.logistics.ImportVesselScheduleDashboardViewSummary[];
            isLoading: boolean;
            PortTerminals: Array<interfaces.applicationcore.IDropdownModel>;
            LoadImportVesselScheduleData();
            loadPortTerminals();
            getTerminalUpdate();
            refreshSummaryView();
            terminalInfo: interfaces.logistics.TerminalInfo;
            navigateToVesselSchedule(vshId:number);
            navigateToConsignment(conId:number);
            getVesselScheduleDetailById(vshId:number): interfaces.logistics.ImportVesselScheduleDashboardView[];
            portTerminalId: number;
            searchText: string;
            searchChanged();
            change(model: interfaces.applicationcore.IDropdownModel): void;
            replaceAll(str : string, find: string, replace: string):string;
            downloadToExcel();

            //HTML5 Drag and Drop Methods

            dragStart($event: any,vesselSchedule:interfaces.logistics.ImportVesselScheduleDashboardView);
            dragDrop($event: any,vesselScheduleSummary:interfaces.logistics.ImportVesselScheduleDashboardViewSummary);
            dragEnter($event:any);
            dragOver($event:any);

        }

        export class importVesselScheduleDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/logistics/importVesselScheduleView.html';
            scope = {
            }

            public imporVesselScheduleData: interfaces.logistics.ImportVesselScheduleDashboardView[];
            public imporVesselScheduleSummaryData: interfaces.logistics.ImportVesselScheduleDashboardViewSummary[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private vesselScheduleService: interfaces.logistics.IVesselScheduleService,
                private $cookies: ng.cookies.ICookiesService,
            ) {
                
            }

            link = ($scope: IImportVesselScheduleDirective, $element: ng.IAugmentedJQuery, $state) => {


                $scope.loadPortTerminals = () => {
                    $scope.isLoading = true;
                    return this.vesselScheduleService.getGetPortTerminalsForDropDown().query({
                    }, (resultList) => {
                        $scope.PortTerminals = resultList;

                        var lastPortTerminalId = this.$cookies.get('lastImportPortTerminalId');
                        if (lastPortTerminalId){

                            $scope.portTerminalId = +lastPortTerminalId;
                            $scope.getTerminalUpdate();
                            $scope.LoadImportVesselScheduleData();
                        }

                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.getTerminalUpdate = () => {
                    return this.vesselScheduleService.GetTerminalUpdate($scope.portTerminalId).query({}, (result) => {
                            $scope.terminalInfo = result;
                    });
                }
   

                $scope.LoadImportVesselScheduleData = () => {
                    $scope.isLoading = true;
                    $scope.searchText = "";
                    return this.vesselScheduleService.getImportVesselScheduleDashboardData($scope.portTerminalId).query({
                    }, (resultList) => {
                         this.$cookies.put('lastImportPortTerminalId',$scope.portTerminalId.toString());
                        $scope.importVesselScheduleData = resultList;
                        $scope.filteredImportVesselScheduleData = resultList;
                        $scope.importVesselScheduleSummaryData =  [];
                        $scope.getTerminalUpdate();
                        $scope.refreshSummaryView();


                        $scope.isLoading = false;
                    }).$promise;
                }

                $scope.refreshSummaryView = () => {
                    $scope.importVesselScheduleSummaryData = [];
                    angular.forEach( $scope.filteredImportVesselScheduleData.sort((a,b)=> b.vshId-a.vshId), (importVesselSchedule) => {
                        
                        var importVesselScheduleSummary =  _.find($scope.importVesselScheduleSummaryData, (o) => { return o.vshId === importVesselSchedule.vshId});
                    
                        if (!importVesselScheduleSummary){
                    
                            importVesselScheduleSummary = <interfaces.logistics.ImportVesselScheduleDashboardViewSummary>{
                                vshId: importVesselSchedule.vshId,
                                TerminalId: importVesselSchedule.TerminalId,
                                VesselId: importVesselSchedule.VesselId,
                                VisitSequence: importVesselSchedule.VisitSequence,
                                CallSign: importVesselSchedule.CallSign,
                                VesselName: importVesselSchedule.VesselName,
                                TimeofBerthing: importVesselSchedule.TimeofBerthing,
                                StorageEnd: importVesselSchedule.StorageEnd,
                                EstimateDateOfArrival: importVesselSchedule.EstimateDateOfArrival,
                                ArrivalComments: importVesselSchedule.ArrivalComments,
                                SLA : 0,
                                IsExpanded: false,
                                SLAComments: ''
                            }
                                            
                            $scope.importVesselScheduleSummaryData.push(importVesselScheduleSummary);
                    
                        }

                        importVesselScheduleSummary =  _.find($scope.importVesselScheduleSummaryData, (o) => { return o.vshId === importVesselSchedule.vshId});

                        if (importVesselSchedule.SLA > importVesselScheduleSummary.SLA){
                            importVesselScheduleSummary.SLA = importVesselSchedule.SLA;

                        }
                            
                        if (importVesselScheduleSummary.SLA > 0){
                            importVesselScheduleSummary.IsExpanded = true;
                        }


                        if (importVesselSchedule.SLAComments !== '')
                        {
                            importVesselScheduleSummary.SLAComments =  importVesselScheduleSummary.SLAComments + ' <br/>' + importVesselSchedule.SLAComments;
                        }

                    });

                   
                }

                $scope.change = (model) => {
                    $scope.LoadImportVesselScheduleData();
                }

                $scope.navigateToVesselSchedule = (vshId:number) => {
                    this.$state.go("auth.VesselSailingSchedule.Update", { vshId: vshId });
                }

                $scope.navigateToConsignment = (conId:number) => {
                    this.$state.go("auth.ConsignmentImports.Update", { conId: conId });
                }


                $scope.getVesselScheduleDetailById =  (vshId:number) => {
                    return $scope.filteredImportVesselScheduleData.filter((o) => {return o.vshId === vshId})
                }


                $scope.searchChanged = () =>{
                    $scope.filteredImportVesselScheduleData = [];
                    for (var i = 0; i < $scope.importVesselScheduleData.length; i++){    
                       
                        if (JSON.stringify($scope.importVesselScheduleData[i]).toLocaleLowerCase().includes($scope.replaceAll($scope.searchText.toLocaleLowerCase(),'/','-')))
                        {
                            $scope.filteredImportVesselScheduleData.push($scope.importVesselScheduleData[i]);
                        }
                    }      
                    $scope.refreshSummaryView();
                }

                 $scope.replaceAll = (str, find, replace) => {
                    return str.replace(new RegExp(find, 'g'), replace);
                  }

                $scope.loadPortTerminals();

                $scope.downloadToExcel = () => {
                    this.vesselScheduleService.getImportVesselScheduleDashboardExcel($scope.portTerminalId).then(() => {
                    }, (data) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                    });
                }


                /*  Drag and Drop HTML5 methods */

                $scope.dragStart = ($event: any,vesselSchedule:interfaces.logistics.ImportVesselScheduleDashboardView) =>{
                    $event.dataTransfer.setData('text', vesselSchedule.conId);
                }

                $scope.dragDrop = ($event: any,vesselScheduleSummary:interfaces.logistics.ImportVesselScheduleDashboardViewSummary) => {
                    $event.preventDefault();
                
                    $scope.isLoading = true;


                    this.vesselScheduleService.allocateConsignmentToVesselSchedule(

                        $event.dataTransfer.getData('text'),
                        vesselScheduleSummary.vshId

                    ).save( (result) => {

                        this.generalService.displayMessageHandler(result);
                        $scope.LoadImportVesselScheduleData();

                        $scope.isLoading = false;
                    });
                }

                $scope.dragEnter = ($event:any) => {
                    $event.preventDefault();
                }

                $scope.dragOver = ($event:any) => {
                    $event.preventDefault();
                }                

                /* End Drag and Drop HTML5 methods */

            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state, 
                    bsLoadingOverlayService, vesselScheduleService,$cookies) => new importVesselScheduleDirective(generalService, $state, bsLoadingOverlayService, vesselScheduleService,$cookies);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService", "vesselScheduleService",'$cookies'];

                return directive;
            }
        }

        angular.module("app").directive("gtsImportVesselSchedule", importVesselScheduleDirective.factory());
    }
}